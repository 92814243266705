import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import styled from 'styled-components';
import SEO from '../components/SEO';

const GalleryImageWideStyles = styled.div`
  h1 {
    padding: 0 4rem 2rem 4rem;
    line-height: 1.25em;

    @media screen and (max-width: 850px) {
      padding: 0 0 2rem 0;
    }
  }

  padding: 2rem;

  @media screen and (max-width: 965px) {
    padding: 0;
  }
`;

const GalleryImageNarrowStyles = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem 2rem 2rem;
  gap: 2rem;

  h1 {
    line-height: 1.25em;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    padding: 0;
  }
`;

const ImageDescriptionNarrowStyles = styled.div`
  @media screen and (min-width: 600px) {
    padding-top: 8rem;
  }
`;

const ImageDescriptionWideStyles = styled.div`
  padding: 2rem 4rem 0 4rem;

  @media screen and (max-width: 965px) {
    padding: 2rem 0 0 0;
  }
`;

const BreadcrumbStyles = styled.ol`
  list-style: none;
  margin-bottom: 0;
  margin-top: 3rem;

  li {
    display: inline;
    text-transform: uppercase;
  }

  li + li::before {
    padding: 0.5rem;
    content: '/\\00a0';
  }

  li a {
    text-decoration: none;
    color: var(--foreground-color);
  }

  li a:hover {
    text-decoration: underline;
  }
`;

export default function GalleryImage({ data: { galleryImage }, pageContext }) {
  const seoImagePath = getSrc(galleryImage.image.asset.gatsbyImageData);

  const imageWidth = galleryImage.image.asset.gatsbyImageData.width;

  if (imageWidth > 670) {
    return (
      <>
        <SEO title={galleryImage.title} image={seoImagePath} />
        <BreadcrumbStyles>
          <li>
            <Link to={`/${pageContext.gallerySlug}`}>
              {pageContext.galleryName}
            </Link>
          </li>
          <li>{galleryImage.title}</li>
        </BreadcrumbStyles>
        <GalleryImageWideStyles>
          <h1>{galleryImage.title}</h1>
          <GatsbyImage
            alt={galleryImage.altText}
            image={galleryImage.image.asset.gatsbyImageData}
            objectFit="contain"
            style={{
              filter: 'drop-shadow(0px 5px 10px var(--dark-grey))',
              display: 'block',
              margin: '0 auto',
            }}
          />
          <ImageDescriptionWideStyles>
            {galleryImage.size && <p>Size: {galleryImage.size}</p>}
            {galleryImage.description && <p>{galleryImage.description}</p>}
          </ImageDescriptionWideStyles>
        </GalleryImageWideStyles>
      </>
    );
  }

  return (
    <>
      <SEO title={galleryImage.title} image={seoImagePath} />
      <BreadcrumbStyles>
        <li>
          <Link to={`/${pageContext.gallerySlug}`}>
            {pageContext.galleryName}
          </Link>
        </li>
        <li>{galleryImage.title}</li>
      </BreadcrumbStyles>
      <GalleryImageNarrowStyles>
        <GatsbyImage
          alt={galleryImage.altText}
          image={galleryImage.image.asset.gatsbyImageData}
          style={{ filter: 'drop-shadow(0px 5px 10px var(--dark-grey))' }}
        />
        <ImageDescriptionNarrowStyles>
          <h1>{galleryImage.title}</h1>
          {galleryImage.size && <p>Size: {galleryImage.size}</p>}
          {galleryImage.description && <p>{galleryImage.description}</p>}
        </ImageDescriptionNarrowStyles>
      </GalleryImageNarrowStyles>
    </>
  );
}

export const query = graphql`
  query ($galleryImageId: String!) {
    galleryImage: sanityGalleryImage(id: { eq: $galleryImageId }) {
      title
      size
      description
      altText
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
